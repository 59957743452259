import "es6-promise/auto";
import images from './images';
import lazysizes from 'lazysizes';
import Swiper from 'swiper';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import { nl } from '@braid/vue-formulate-i18n'


Vue.use(VueFormulate, {
    plugins: [ nl ],
    locale: 'nl'
})

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue.default({
        el: "#site",
        delimiters: ['${', '}'],
        components: {
            'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
            'parallax': () => import(/* webpackChunkName: "parallax" */ '../vue/parallax.vue'),
            'modal': () => import(/* webpackChunkName: "parallax" */ '../vue/Modal.vue'),
            'brochure-form': () => import(/* webpackChunkName: "parallax" */ '../vue/forms/BrochureForm.vue'),
            'direct-bestellen-form': () => import(/* webpackChunkName: "parallax" */ '../vue/forms/DirectBestellenForm.vue'),
            'werkgever-form': () => import(/* webpackChunkName: "parallax" */ '../vue/forms/WerkgeverForm.vue'),
            'e-learning': () => import(/* webpackChunkName: "parallax" */ '../vue/Elearning.vue'),
            'loader': () => import(/* webpackChunkName: "loader" */ '../vue/Loader.vue'),
        },
        data: {
            'mobileClass': false,
            'showModal1': false,
            'showElearningModel': false,
            'eLearningModelType': 1,
            'loading': false,
        },
        methods: {
            openMobileNav: function() {
                this.mobileClass = !this.mobileClass;
                if (this.mobileClass) {
                    document.getElementsByTagName("html")[0].setAttribute("class", "overflow-hidden");
                    document.getElementsByTagName("body")[0].setAttribute("class", "overflow-hidden");
                } else {
                    document.getElementsByTagName("html")[0].setAttribute("class", "");
                    document.getElementsByTagName("body")[0].setAttribute("class", "");
                }
            },
            openPopup(type) {
                this.eLearningModelType = type;
                this.showElearningModel = true;
            },
            loadingForm: function () {
                this.loading = true;
            }
        },
        mounted() {
            require('./javascript');

            var logoSlider = new Swiper('.js-logos-slider', {
                slidesPerView: 5,
                loop: true,
                autoplay: {
                    delay: 2500
                },
                breakpoints: {
                    992: {
                        slidesPerView: 3
                    },
                    600: {
                        slidesPerView: 2
                    }
                }
            });
            var personeel = new Swiper('.js-personeel-slider', {
                slidesPerView: 3,
                slidesPerGroup: 2,
                spaceBetween: 51,
                navigation: {
                    nextEl: '.swiper-personeel-next',
                    prevEl: '.swiper-personeel-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                },
                breakpoints: {
                    992: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                    },
                    600: {
                        slidesPerGroup: 1,
                        slidesPerView: 1
                    }
                }
            });
        },
    });
};
// Execute async function
main().then( (value) => {
});

