/* global $, openTab */

/**
 * Utility to wrap the different behaviors between W3C-compliant browsers
 * and IE when adding event handlers.
 *
 * @param {Object}      element Object on which to attach the event listener.
 * @param {string}      type A string representing the event type to listen for
 *                      (e.g. load, click, etc.).
 * @param {function()}  callback The function that receives the notification.
 */

function addListener(element, type, callback) {
    if (element.addEventListener) {
        element.addEventListener(type, callback);
    } else if (element.attachEvent) {
        element.attachEvent('on' + type, callback);
    }
}

window.onload = function () {
    var ulHasChildren = document.getElementsByClassName('menu__list');
    for (var i = 0; i < ulHasChildren.length; i++) {
        if (ulHasChildren[i].childNodes.length === 2) {
            ulHasChildren[i].classList.add('has-children');
        }
    }

    var menuChildToggle = document.getElementsByClassName('has-children');
    for (var k = 0; k < menuChildToggle.length; k++) {
        menuChildToggle[k].addEventListener('click', function (k) {
            return function () {
                var menu = menuChildToggle[k].childNodes;
                if (menuChildToggle[k].classList) {
                    menuChildToggle[k].classList.toggle('open-parent');
                    menu[1].classList.toggle('open');
                }
            };
        }(k), false);
    }
};

(function () {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var notificationIE = document.getElementsByClassName('notificationIE')[0];
    var exit = document.getElementsByClassName('notificationIE-exit')[0];
    if (isIE === true) {
        var exitIEFunction = function exitIEFunction() {
            notificationIE.parentNode.removeChild(notificationIE);
        };

        notificationIE.classList.add('block');
        exit.onclick = function () {
            exitIEFunction();
        };
    } else {
        notificationIE.parentNode.removeChild(notificationIE);
    }
})();


(function () {
    var aHref = document.querySelectorAll('a[href^="#"]');
    for (var k = 0; k < aHref.length; k++) {
        aHref[k].addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    }
})();

(function () {
    var faqItem = document.getElementsByClassName('faq__item');
    for (var k = 0; k < faqItem.length; k++) {
        faqItem[k].addEventListener('click', function (k) {
            return function () {
                if (faqItem[k].classList) {
                    faqItem[k].classList.toggle('active');
                }
            };
        }(k), false);
    }
})();

window.onscroll = function () {
    scrollFunction();

    const credits = document.querySelector('#creditsNiice');
    // Check if we're on mobile
    if (window.innerWidth < 768) {
        // Check if the credits are visible
        if (credits.getBoundingClientRect().bottom < (window.innerHeight || document.documentElement.clientHeight)) {
            credits.classList.add('visible-credits');
        } else {
            credits.classList.remove('visible-credits');
        }
    } else {
        credits.classList.remove('visible-credits');
    }
};
function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementsByClassName("scrollTop")[0].style.display = "flex";
    } else {
        document.getElementsByClassName("scrollTop")[0].style.display = "none";
    }
}
